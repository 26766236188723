import { QueryInfo } from './Store';
import * as Store from "./Store";

// ---------------------------------------------------------------------------
// The action type
// ---------------------------------------------------------------------------

export enum ActionType {
    CONFIGURE_APP = 'CONFIGURE_APP',
    NAVIGATE_ROOT = 'NAVIGATE_ROOT',
    NAVIGATE_SQL_LAB = 'NAVIGATE_SQL_LAB',
    PUSH_LOG_ENTRY = 'PUSH_LOG_ENTRY',
    SHOW_LOG_VIEWER = 'SHOW_LOG_VIEWER',
    TOGGLE_LOG_VIEWER = 'TOGGLE_LOG_VIEWER',
    SERVER_DESELECT = 'SERVER_DESELECT',
    SERVER_INFO_UPDATE = 'SERVER_STATUS_UPDATE',
    SERVER_SELECT = 'SERVER_SELECT',
    LAB_QUERY_RESULT = 'LAB_QUERY_RESULT',
    LAB_QUERY_ABORT = 'LAB_QUERY_ABORT',
    LAB_QUERY_FAILED = 'LAB_QUERY_FAILED',
    LAB_PLAN_RESULT = 'LAB_PLAN_RESULT',
    LAB_PLAN_APPEND = 'LAB_PLAN_APPEND',
    LAB_PLAN_PREPEND = 'LAB_PLAN_PREPEND',
    LAB_QUERY_START = 'LAB_QUERY_START',
    LAB_QUERY_TEXT_UPDATE = 'LAB_QUERY_TEXT_UPDATE',
    LAB_QUERY_INFO_UPDATE = 'LAB_QUERY_INFO_UPDATE',
    LAB_QUERY_THEME_UPDATE = 'LAB_QUERY_THEME_UPDATE',
    RESTORE_STORE_STATE = 'RESTORE_STORE_STATE',
    OTHER = 'OTHER',
}

// ---------------------------------------------------------------------------
// The root action type
// ---------------------------------------------------------------------------

export type RootAction =
    | Action<ActionType.CONFIGURE_APP, Store.AppConfig>
    | Action<ActionType.NAVIGATE_ROOT, Store.RootView>
    | Action<ActionType.NAVIGATE_SQL_LAB, number>
    | Action<ActionType.PUSH_LOG_ENTRY, Store.LogEntry>
    | Action<ActionType.SHOW_LOG_VIEWER, {}>
    | Action<ActionType.TOGGLE_LOG_VIEWER, {}>
    | Action<ActionType.SERVER_DESELECT, {}>
    | Action<ActionType.SERVER_INFO_UPDATE, [string, Partial<Store.ServerInfo>]>
    | Action<ActionType.SERVER_SELECT, string>
    | Action<ActionType.LAB_QUERY_FAILED, Error>
    | Action<ActionType.LAB_QUERY_ABORT, {}>
    | Action<ActionType.LAB_QUERY_RESULT, Store.QueryResult>
    | Action<ActionType.LAB_PLAN_RESULT, Store.PlanResult>
    | Action<ActionType.LAB_PLAN_APPEND, any>
    | Action<ActionType.LAB_PLAN_PREPEND, any>
    | Action<ActionType.LAB_QUERY_START, {}>
    | Action<ActionType.LAB_QUERY_TEXT_UPDATE, string>
    | Action<ActionType.LAB_QUERY_INFO_UPDATE, QueryInfo>
    | Action<ActionType.LAB_QUERY_THEME_UPDATE, string>
    | Action<ActionType.RESTORE_STORE_STATE, Store.RootState>
    | Action<ActionType.OTHER, {}>;

// ---------------------------------------------------------------------------
// The action creators
// ---------------------------------------------------------------------------

export class Action<T, P> {
    public readonly type: T;
    public readonly payload: P;

    constructor(type: T, payload: P) {
        this.type = type;
        this.payload = payload;
    }
}

export function createAction<T, P>(type: T, payload: P): Action<T, P> {
    return {type, payload};
}

export function pushLogEntry(log: Store.LogEntry): RootAction {
    return createAction<ActionType.PUSH_LOG_ENTRY, Store.LogEntry>(ActionType.PUSH_LOG_ENTRY, log);
}

export function showLogViewer(): RootAction {
    return createAction<ActionType.SHOW_LOG_VIEWER, {}>(ActionType.SHOW_LOG_VIEWER, {});
}

export function toggleLogViewer(): RootAction {
    return createAction<ActionType.TOGGLE_LOG_VIEWER, {}>(ActionType.TOGGLE_LOG_VIEWER, {});
}

export function navigateRoot(view: Store.RootView): RootAction {
    return createAction<ActionType.NAVIGATE_ROOT, Store.RootView>(ActionType.NAVIGATE_ROOT, view);
}

export function navigateLab(tabID: number): RootAction {
    return createAction<ActionType.NAVIGATE_SQL_LAB, number>(ActionType.NAVIGATE_SQL_LAB, tabID);
}

export function configureApp(config: Store.AppConfig): RootAction {
    return createAction<ActionType.CONFIGURE_APP, Store.AppConfig>(ActionType.CONFIGURE_APP, config);
}

export function selectServer(key: string): RootAction {
    return createAction<ActionType.SERVER_SELECT, string>(ActionType.SERVER_SELECT, key);
}

export function deselectServer(): RootAction {
    return createAction<ActionType.SERVER_DESELECT, {}>(ActionType.SERVER_DESELECT, {});
}

export function updateServerInfo(key: string, serverInfo: Partial<Store.ServerInfo>): RootAction {
    return createAction<ActionType.SERVER_INFO_UPDATE, [string, Partial<Store.ServerInfo>]>(ActionType.SERVER_INFO_UPDATE, [key, serverInfo]);
}

export function startLabQuery(): RootAction {
    return createAction<ActionType.LAB_QUERY_START, {}>(ActionType.LAB_QUERY_START, {});
}

export function abortLabQuery(): RootAction {
    return createAction<ActionType.LAB_QUERY_ABORT, {}>(ActionType.LAB_QUERY_ABORT, {});
}

export function queryFailed(error: Error): RootAction {
    return createAction<ActionType.LAB_QUERY_FAILED, Error>(ActionType.LAB_QUERY_FAILED, error);
}

export function storeQueryResult(result: Store.QueryResult): RootAction {
    return createAction<ActionType.LAB_QUERY_RESULT, Store.QueryResult>(ActionType.LAB_QUERY_RESULT, result);
}

export function updateQueryText(text: string): RootAction {
    return createAction<ActionType.LAB_QUERY_TEXT_UPDATE, string>(ActionType.LAB_QUERY_TEXT_UPDATE, text);
}

export function updateTheme(text: string): RootAction {
    return createAction<ActionType.LAB_QUERY_THEME_UPDATE, string>(ActionType.LAB_QUERY_THEME_UPDATE, text);
}

export function updateQueryInfo(schemaId: string, scale: number | undefined, skew: number | undefined): RootAction {
    return createAction<ActionType.LAB_QUERY_INFO_UPDATE, QueryInfo>(ActionType.LAB_QUERY_INFO_UPDATE, {
        schemaId: schemaId,
        scale: scale,
        skew: skew
    });
}

export function appendPlanStep(plan: any): RootAction {
    return createAction<ActionType.LAB_PLAN_APPEND, Store.PlanResult>(ActionType.LAB_PLAN_APPEND, plan);
}

export function prependPlanStep(plan: any): RootAction {
    return createAction<ActionType.LAB_PLAN_PREPEND, Store.PlanResult>(ActionType.LAB_PLAN_PREPEND, plan);
}

export function storePlanResult(result: Store.PlanResult): RootAction {
    return createAction<ActionType.LAB_PLAN_RESULT, Store.PlanResult>(ActionType.LAB_PLAN_RESULT, result);
}

export function restoreStoreState(state: Store.RootState): RootAction {
    return createAction<ActionType.RESTORE_STORE_STATE, Store.RootState>(ActionType.RESTORE_STORE_STATE, state);
}
