/* eslint import/no-webpack-loader-syntax: off */
import { QueryList } from '../../store';
import q11 from '!raw-loader!./11.sql';
import q12 from '!raw-loader!./12.sql';
import q13 from '!raw-loader!./13.sql';
import q21 from '!raw-loader!./21.sql';
import q22 from '!raw-loader!./22.sql';
import q23 from '!raw-loader!./23.sql';
import q31 from '!raw-loader!./31.sql';
import q32 from '!raw-loader!./32.sql';
import q33 from '!raw-loader!./33.sql';
import q34 from '!raw-loader!./34.sql';
import q41 from '!raw-loader!./41.sql';
import q42 from '!raw-loader!./42.sql';
import q43 from '!raw-loader!./43.sql';

export const queries : QueryList = {
    name: "SSB",
    queries: [
        {name: "11", text: [q11]},
        {name: "12", text: [q12]},
        {name: "13", text: [q13]},
        {name: "21", text: [q21]},
        {name: "22", text: [q22]},
        {name: "23", text: [q23]},
        {name: "31", text: [q31]},
        {name: "32", text: [q32]},
        {name: "33", text: [q33]},
        {name: "34", text: [q34]},
        {name: "41", text: [q41]},
        {name: "42", text: [q42]},
        {name: "43", text: [q43]}
    ]
}
