import * as React from 'react';
import * as Store from '../../store';
import LogViewer from './log-viewer/LogViewer';
import { connect } from 'react-redux';
import classNames from 'classnames';
import * as HTTPApi from '../../utils/HTTPApi';

import s from './StatusBar.module.scss';

import {
    WarningIcon,
} from '../../svg/Icons';
import { isBrowser, isTablet } from 'react-device-detect';

const connStatusNames = new Map<Store.ConnectionStatus, string>([
    [Store.ConnectionStatus.CS_CONNECTED, 'connected'],
    [Store.ConnectionStatus.CS_DISCONNECTED, 'disconnected'],
    [Store.ConnectionStatus.CS_UNDEFINED, 'undefined'],
]);

interface IStatusBarProps {
    serverConfig: Store.ServerConfig | null;
    serverInfo: Store.ServerInfo | null;
    logWarnings: number;
    logsOpen: boolean;

    toggleLogViewer: () => void;
    deselectServer: () => void;
}

export class StatusBar extends React.Component<IStatusBarProps> {
    constructor(props: IStatusBarProps) {
        super(props);
        this.state = {
            logsOpen: false
        };
    }

    public render() {
        let connStatus = Store.ConnectionStatus.CS_UNDEFINED;
        let url = '-';
        let version = '-';
        if (this.props.serverInfo) {
            connStatus = this.props.serverInfo.connectionStatus;
            version = this.props.serverInfo.version;
        }
        if (this.props.serverConfig) {
            url = HTTPApi.buildURL(this.props.serverConfig);
        }

        return (
            <div className={s.statusBar}>
                <div className={s.left}>
                    {
                        this.props.serverConfig &&
                        <div className={s.server} onClick={this.props.deselectServer}>
                            {(isBrowser || isTablet) &&
                                <span className={s.subComponent}>
                                    server:&nbsp;<b>{url}</b>
                                </span>
                            }
                            <span className={s.subComponent}>
                                status:&nbsp;<b>{connStatusNames.get(connStatus) || '-'}</b>
                            </span>
                            <span className={s.subComponent}>
                                version:&nbsp;<b>{version}</b>
                            </span>
                        </div>
                    }
                </div>
                <div
                    className={classNames({
                        [s.logStats]: true,
                        [s.logStatsActive]: this.props.logsOpen
                    })}
                    onClick={() => this.props.toggleLogViewer()}
                >
                    <div className={s.logStat}>
                        <div className={s.icon}>
                            <WarningIcon width="13px" height="13px" />
                        </div>
                        <div className={s.name}>
                            warnings: <b>{this.props.logWarnings}</b>
                        </div>
                    </div>
                </div>
                {
                    this.props.logsOpen && (
                        <div className={s.logViewer}>
                            <LogViewer close={() => this.props.toggleLogViewer()} />
                        </div>
                    )
                }
            </div>
        );
    }
}

// Map state to props
function mapStateToProps(state: Store.RootState) {
    const serverConfig = (state.selectedServer && state.serverConfigs.get(state.selectedServer)) || null;
    const serverInfo = (state.selectedServer && state.serverInfos.get(state.selectedServer)) || null;
    return {
        logWarnings: state.logWarnings,
        logsOpen: state.logViewerOpen,
        serverConfig,
        serverInfo,
    };
}
// Map llvm explorer dispatchs
function mapDispatchToProps(dispatch: Store.Dispatch) {
    return {
        deselectServer: () => { dispatch(Store.deselectServer()); },
        toggleLogViewer: () => dispatch(Store.toggleLogViewer()),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(StatusBar);
