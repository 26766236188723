/* eslint import/no-webpack-loader-syntax: off */
import { QueryList } from '../../store';
import q1 from '!raw-loader!./professors.sql';
import q2 from '!raw-loader!./students.sql';
import q3 from '!raw-loader!./lectures.sql';
import q4 from '!raw-loader!./require.sql';
import q5 from '!raw-loader!./attend.sql';
import q6 from '!raw-loader!./assistants.sql';
import q7 from '!raw-loader!./test.sql';

export const queries: QueryList = {
    name: "Uni Schema",
    queries: [
        { name: "professors", text: [q1] },
        { name: "students", text: [q2] },
        { name: "lectures", text: [q3] },
        { name: "require", text: [q4] },
        { name: "attend", text: [q5] },
        { name: "assistants", text: [q6] },
        { name: "test", text: [q7] }
    ]
}
