import * as Store from '../store';
import * as HTTP from './HTTP';

// A server version
export class ServerVersion {
    public server: string = "";
    public version: string = "";
}

// A query result
class QueryResult {
    public columns: any[] = [];
    public compilationTime: number = 0;
    public executionTime: number = 0;
    public resultCount: number = 0;
    public result: any[][] = [];
}

// A data source result
class QueryResults {
    public parseTime: number = 0;
    public results: QueryResult[] = [];
}

class PlanResult {
    public name: string = '';
    plan: {
        plan: any,
        output: { name: string, iu: string, collate: string }[],
        type: number,
        query: boolean,
        analyzePlanPipelines?: { start: number, stop: number, duration: number, operators: number[] }[]
    } = {plan: {}, output: [], type: 0, query: false};
}

class PlanVerboseResult {
    public optimizersteps: PlanResult[] = [];
    public code: any = undefined
}

// Build a URL for the HTTP Api
export function buildURL(config: Store.ServerConfig): string {
    if ("path" in config.connection) {
        return config.connection.path;
    } else {
        const proto = config.connection.protocol === Store.ConnectionProtocol.CP_HTTPS ? "https" : "http";
        if (config.connection.port)
            return `${proto}://${config.connection.host}:${config.connection.port}`;
        else
            return `${proto}://${config.connection.host}`;
    }
}

// Get a version
export function getVersion(config: Store.ServerConfig): Promise<ServerVersion> {
    const url = `${buildURL(config)}/version`;
    return HTTP.loadWithTimeout<ServerVersion>(url, {}, 500);
}

// Post a query
export function postQuery(config: Store.ServerConfig, query: string): Promise<Store.QueryResult> {
    const url = `${buildURL(config)}/query`;
    return HTTP.loadWithTimeout<QueryResults>(url, {
        body: query,
        method: 'POST',
    }, 10000)
    .then((qRs) => {
        let qR = qRs.results[0];
        const dsR = new Store.QueryResult();
        dsR.compilationTime = qR.compilationTime;
        dsR.executionTime = qR.executionTime;
        dsR.resultCount = qR.resultCount;
        dsR.columns = new Array<Store.QueryResultColumn>();
        for (let i = 0; i < Math.min(qR.columns.length, qR.result.length); ++i) {
            dsR.columns.push({
                columnName: qR.columns[i].name,
                columnType: qR.columns[i].type,
                data: qR.result[i],
            })
        }
        return dsR;
    });
}

export async function postPlanVerbose(config: Store.ServerConfig, query: string): Promise<Store.PlanResult> {
    const url = `${buildURL(config)}/planVerbose`;
    let pR = await HTTP.loadWithTimeout<PlanVerboseResult>(url, {
        body: query,
        method: 'POST',
    }, 10000);

    const dsR = new Store.PlanResult();
    dsR.optimizersteps = pR.optimizersteps;
    dsR.code = pR.code;
    return dsR;
}

export async function postPlanVerboseAnalyze(config: Store.ServerConfig, query: string): Promise<Store.PlanResult> {
    const url = `${buildURL(config)}/planVerboseAnalyze`;
    let pR = await HTTP.loadWithTimeout<PlanVerboseResult>(url, {
        body: query,
        method: 'POST',
    }, 10000);

    const dsR = new Store.PlanResult();
    dsR.optimizersteps = pR.optimizersteps;
    return dsR;
}
