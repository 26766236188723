import { Store as ReduxStoreOrig } from 'redux';
import { RootState } from './Store';
import { RootAction } from './Action';
import {keys} from "d3";

// Export things
export * from './Reducer';
export * from './Action';
export * from './Store';

// The action dispatch
export type Dispatch = (action: RootAction) => void;
// The store type
export type ReduxStore = ReduxStoreOrig<RootState>;

export let getSessionStorage : () => Storage = () => sessionStorage;

try {
    sessionStorage.getItem("test");
} catch (e) {
    class DummyStorage {
        private keys: (string | null)[] = [];
        private dict: Map<string, [number, string]> = new Map();

        get length() {
            return this.dict.size;
        }

        clear() {
            this.dict.clear();
        }

        getItem(key: string) : string | null {
            const res = this.dict.get(key);
            if (res === undefined)
                return null;
            return res[1];
        }

        key(index: number) : string | null {
            if (index < this.keys.length)
                return this.keys[index];
            return null;
        }

        removeItem(key: string) {
            const res = this.dict.get(key);
            if (res === undefined)
                return;
            this.keys[res[0]] = null;
            this.dict.delete(key);
        }

        setItem(key: string, value: string) {
            let res = this.dict.get(key)
            if (res !== undefined) {
                res[1] = value;
            } else {
                let num = this.keys.length;
                this.keys.push(key);
                this.dict.set(key, [num, value]);
            }
        }
    }
    let myStorage = new DummyStorage();
    getSessionStorage = () => myStorage;
}