/* eslint import/no-webpack-loader-syntax: off */
import { QueryList } from '../../store';
import q1 from '!raw-loader!./1.sql';
import q2a from '!raw-loader!./2a.sql';
import q2b from '!raw-loader!./2b.sql';
import q3 from '!raw-loader!./3.sql';
import q4 from '!raw-loader!./4.sql';
import q5 from '!raw-loader!./5.sql';
import q6 from '!raw-loader!./6.sql';
import q7 from '!raw-loader!./7.sql';
import q8a from '!raw-loader!./8a.sql';
import q8b from '!raw-loader!./8b.sql';
import q9 from '!raw-loader!./9.sql';
import q10a from '!raw-loader!./10a.sql';
import q10b from '!raw-loader!./10b.sql';
import q11 from '!raw-loader!./11.sql';
import q12 from '!raw-loader!./12.sql';
import q13 from '!raw-loader!./13.sql';
import q14a from '!raw-loader!./14a.sql';
import q14b from '!raw-loader!./14b.sql';
import q15a from '!raw-loader!./15a.sql';
import q15b from '!raw-loader!./15b.sql';
import q16a from '!raw-loader!./16a.sql';
import q16b from '!raw-loader!./16b.sql';
import q17 from '!raw-loader!./17.sql';
import q18 from '!raw-loader!./18.sql';

export const queries : QueryList = {
    name: "LDBC",
    queries: [
        {name: "1", text: [q1]},
        {name: "2a", text: [q2a]},
        {name: "2b", text: [q2b]},
        {name: "3", text: [q3]},
        {name: "4", text: [q4]},
        {name: "5", text: [q5]},
        {name: "6", text: [q6]},
        {name: "7", text: [q7]},
        {name: "8a", text: [q8a]},
        {name: "8b", text: [q8b]},
        {name: "9", text: [q9]},
        {name: "10a", text: [q10a]},
        {name: "10b", text: [q10b]},
        {name: "11", text: [q11]},
        {name: "12", text: [q12]},
        {name: "13", text: [q13]},
        {name: "14a", text: [q14a]},
        {name: "14b", text: [q14b]},
        {name: "15a", text: [q15a]},
        {name: "15b", text: [q15b]},
        {name: "16a", text: [q16a]},
        {name: "16b", text: [q16b]},
        {name: "17", text: [q17]},
        {name: "18", text: [q18]}
    ]
}
