import * as React from 'react';

interface IProps {
    width?: string;
    height?: string;
    fill?: string;
}

export function Logo(props: IProps) {
    return (
        <svg 
            width={props.width ? props.width : '24px'}
            height={props.height ? props.width : '24px'}
            viewBox="0 0 50 50"
        >
            <g transform="matrix(0.543833,0,0,0.543833,0,0)" fill={props.fill || '#ffffff'} fillRule="nonzero">
                <path d="M76.61,76.79C58.58,74.31 50.59,70.55 48.47,68.6L48.47,60.9C57.76,65.15 71.89,67.33 83.18,68.43C81.34,71.48 79.14,74.28 76.61,76.79Z"/>
                <path d="M50.97,64.63C59.12,67.62 69.54,69.42 78.79,70.49C77.84,71.76 76.81,72.98 75.71,74.14C61.44,72.07 53.64,69.09 50.98,67.39L50.98,64.63M47.01,57.52C46.46,57.52 45.97,57.95 45.97,58.56L45.97,69.18C45.97,69.41 46.03,69.63 46.18,69.81C48.88,73.19 59.88,77.08 76.97,79.37C77.02,79.38 77.06,79.38 77.11,79.38C77.38,79.38 77.65,79.28 77.85,79.09C81.29,75.78 84.22,71.94 86.5,67.69C86.85,67.04 86.4,66.24 85.66,66.17C70.62,64.83 55.78,62.14 47.52,57.65C47.35,57.56 47.18,57.52 47.01,57.52Z" />
                <path d="M87.39,59.2C63.91,57.24 51.4,52.6 48.47,49.84L48.47,42.14C59.69,47.27 78.03,49.36 89.23,50.19C88.94,53.26 88.32,56.28 87.39,59.2Z" />
                <path d="M50.97,45.85C61.62,49.75 76.16,51.61 86.42,52.47C86.2,53.84 85.92,55.19 85.56,56.53C65.32,54.65 54.29,50.78 50.96,48.63L50.96,45.85M47.01,38.74C46.46,38.74 45.97,39.17 45.97,39.78L45.97,50.4C45.97,50.63 46.03,50.85 46.17,51.03C49.43,55.11 64.77,59.93 88.31,61.78L88.39,61.78C88.83,61.78 89.24,61.5 89.39,61.08C90.73,57.23 91.57,53.14 91.83,48.89C91.87,48.32 91.43,47.83 90.86,47.79C74.13,46.65 56.72,43.87 47.51,38.86C47.35,38.78 47.18,38.74 47.01,38.74Z" />
                <path d="M89.1,40.55C65.78,38.77 51.69,34.1 48.47,31.05L48.47,23.34C59.2,28.24 76.53,30.35 86.86,31.21C87.95,34.24 88.7,37.37 89.1,40.55Z"/>
                <path d="M50.97,27.07C60.86,30.71 74.14,32.59 85.02,33.56C85.46,34.95 85.83,36.36 86.12,37.79C65.56,35.94 54.35,32.04 50.97,29.84L50.97,27.07M47.01,19.95C46.47,19.95 45.97,20.38 45.97,20.99L45.97,31.61C45.97,31.84 46.03,32.06 46.17,32.24C49.54,36.45 65.78,41.45 90.64,43.17L90.71,43.17C91.31,43.17 91.81,42.65 91.76,42.04C91.39,37.64 90.39,33.42 88.87,29.46C88.73,29.08 88.37,28.83 87.97,28.8C72.15,27.54 56.18,24.8 47.5,20.08C47.35,19.99 47.18,19.95 47.01,19.95Z" />
                <path d="M45.97,89.44C22,89.44 2.5,69.94 2.5,45.97C2.5,22 22,2.5 45.97,2.5C60.35,2.5 73.47,9.39 81.61,21.07C80.62,20.96 79.63,20.84 78.65,20.72L78.48,20.7C60.43,18.4 50.92,14.54 48.47,12.27L48.47,4L45.97,4C22.83,4 4,22.83 4,45.97C4,69.11 22.83,87.94 45.97,87.94L48.47,87.94L48.47,79.69C52.8,81.67 58.55,83.36 65.63,84.74C59.56,87.83 52.84,89.44 45.97,89.44Z" />
                <path d="M50.97,5.3C60.52,6.45 69.22,10.86 75.75,17.8C61.47,15.73 53.65,12.76 50.97,11.05L50.97,6.5L50.97,5.3M50.97,83.41C52.86,84.11 54.95,84.76 57.21,85.37C55.17,85.95 53.09,86.37 50.97,86.63L50.97,85.44L50.97,83.41M45.97,0C20.58,0 0,20.58 0,45.97C0,71.36 20.58,91.94 45.97,91.94C54.95,91.94 63.32,89.36 70.4,84.91C71.21,84.4 70.95,83.15 70.01,82.99C60.91,81.47 52.85,79.32 47.52,76.42C47.36,76.33 47.18,76.29 47.02,76.29C46.47,76.29 45.98,76.72 45.98,77.33L45.98,85.44C24.21,85.45 6.5,67.74 6.5,45.97C6.5,24.21 24.21,6.5 45.97,6.5L45.97,12.84C45.97,13.07 46.03,13.29 46.17,13.47C48.93,16.93 60.39,20.92 78.16,23.18L78.17,23.19L78.18,23.18L78.19,23.18C80.18,23.43 82.24,23.67 84.39,23.87C84.43,23.87 84.46,23.88 84.5,23.88C85.3,23.88 85.81,22.99 85.38,22.29C77.33,8.93 62.7,0 45.97,0Z" />
            </g>
        </svg>
    );
}
