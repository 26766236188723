/* eslint import/no-webpack-loader-syntax: off */
import { QueryList } from '../../store';
import q0 from '!raw-loader!./0.sql';
import q1 from '!raw-loader!./1.sql';
import q2 from '!raw-loader!./2.sql';
import q3 from '!raw-loader!./3.sql';
import q4 from '!raw-loader!./4.sql';
import q5 from '!raw-loader!./5.sql';
import q6 from '!raw-loader!./6.sql';
import q7 from '!raw-loader!./7.sql';
import q8 from '!raw-loader!./8.sql';
import q9 from '!raw-loader!./9.sql';
import q10 from '!raw-loader!./10.sql';
import q11 from '!raw-loader!./11.sql';
import q12 from '!raw-loader!./12.sql';
import q13 from '!raw-loader!./13.sql';
import q14 from '!raw-loader!./14.sql';
import q15 from '!raw-loader!./15.sql';
import q16 from '!raw-loader!./16.sql';
import q17 from '!raw-loader!./17.sql';
import q18 from '!raw-loader!./18.sql';
import q19 from '!raw-loader!./19.sql';
import q20 from '!raw-loader!./20.sql';
import q21 from '!raw-loader!./21.sql';
import q22 from '!raw-loader!./22.sql';
import q23 from '!raw-loader!./23.sql';
import q24 from '!raw-loader!./24.sql';
import q25 from '!raw-loader!./25.sql';
import q26 from '!raw-loader!./26.sql';
import q27 from '!raw-loader!./27.sql';
import q28 from '!raw-loader!./28.sql';
import q29 from '!raw-loader!./29.sql';
import q30 from '!raw-loader!./30.sql';
import q31 from '!raw-loader!./31.sql';
import q32 from '!raw-loader!./32.sql';
import q33 from '!raw-loader!./33.sql';
import q34 from '!raw-loader!./34.sql';
import q35 from '!raw-loader!./35.sql';
import q36 from '!raw-loader!./36.sql';
import q37 from '!raw-loader!./37.sql';
import q38 from '!raw-loader!./38.sql';
import q39 from '!raw-loader!./39.sql';
import q40 from '!raw-loader!./40.sql';
import q41 from '!raw-loader!./41.sql';
import q42 from '!raw-loader!./42.sql';

export const queries: QueryList = {
    name: "ClickBench",
    queries: [
        { name: "0", text: [q0] },
        { name: "1", text: [q1] },
        { name: "2", text: [q2] },
        { name: "3", text: [q3] },
        { name: "4", text: [q4] },
        { name: "5", text: [q5] },
        { name: "6", text: [q6] },
        { name: "7", text: [q7] },
        { name: "8", text: [q8] },
        { name: "9", text: [q9] },
        { name: "10", text: [q10] },
        { name: "11", text: [q11] },
        { name: "12", text: [q12] },
        { name: "13", text: [q13] },
        { name: "14", text: [q14] },
        { name: "15", text: [q15] },
        { name: "16", text: [q16] },
        { name: "17", text: [q17] },
        { name: "18", text: [q18] },
        { name: "19", text: [q19] },
        { name: "20", text: [q20] },
        { name: "21", text: [q21] },
        { name: "22", text: [q22] },
        { name: "23", text: [q23] },
        { name: "24", text: [q24] },
        { name: "25", text: [q25] },
        { name: "26", text: [q26] },
        { name: "27", text: [q27] },
        { name: "28", text: [q28] },
        { name: "29", text: [q29] },
        { name: "30", text: [q30] },
        { name: "31", text: [q31] },
        { name: "32", text: [q32] },
        { name: "33", text: [q33] },
        { name: "34", text: [q34] },
        { name: "35", text: [q35] },
        { name: "36", text: [q36] },
        { name: "37", text: [q37] },
        { name: "38", text: [q38] },
        { name: "39", text: [q39] },
        { name: "40", text: [q40] },
        { name: "41", text: [q41] },
        { name: "42", text: [q42] },
    ]
}
