import React from 'react';
import {connect} from 'react-redux';
import dagreD3 from 'dagre-d3';
import classNames from 'classnames';

import * as Store from '../../../store';
import {IAppContext, withAppContext} from '../../../AppContext';
import {NormalizePlan, PlanGraphElement} from './NormalizePlan';
import {OptimizerStep} from './models/Plan';

import GraphComponent from './GraphComponent';
import DetailComponent from './DetailComponent';

import s from './QueryPlan.module.scss';
import m from './MobileQueryPlan.module.scss';
import {isMobile} from 'react-device-detect';

interface IRGlobal {
    name: string,
    size: number,
    data: string
}

interface IRInstruction {
    dst: string | number,
}

interface IRBlock {
    label: string | number,
    instructions: IRInstruction[]
}

interface IRFunction {
    name: string,
    returnType: string,
    args: any[],
    variables?: any[],
    blocks?: IRBlock[]
}

interface IRCode {
    globals: IRGlobal[];
    functions: IRFunction[];
}

const renderCode: (code: IRCode) => string = (code) => {
    let res = "";
    for (let global of code.globals)
        res += `const ${global.name};\n`;
    res += "\n";

    for (let fun of code.functions) {
        res += `define ${fun.returnType} ${fun.name}`;
        res += "(...)";
        if (fun.variables && fun.variables.length !== 0) {
            res += "\n[...]";
        }
        if (!fun.blocks) {
            res += "\n";
            continue;
        }

        res += " {\n";
        for (let block of fun.blocks) {
            res += `${block.label}:\n`;
            for (let instruction of block.instructions)
                res += `   ${JSON.stringify(instruction)}\n`;
        }
        res += "}\n";
    }

    return res
};

interface IQueryPlanProps {
    /** AppContext to be able to execute fetching of analyzed plan */
    appContext: IAppContext;
    /** list of all OptimizerSteps */
    optimizersteps?: OptimizerStep[];
    /** list of all OptimizerSteps */
    code?: IRCode;
}

interface IQueryPlanState {
    /** currently selected optimizer step */
    optimizationLevel: number;
    /** should we show the IR code? */
    showCode: boolean;
    /** root node of currently selected optimizer step */
    rootElement?: PlanGraphElement | any;
    /** currently selected node to display in the details area */
    detailsNode?: dagreD3.Node;
}

/**
 * Root Component for QueryPlan viewer
 */
export class QueryPlan extends React.Component<IQueryPlanProps, IQueryPlanState> {
    private graphComponentRef = React.createRef<GraphComponent>();

    constructor(props: IQueryPlanProps) {
        super(props);
        const defaultOptimizationLevel = (this.props.optimizersteps || []).length - 1;
        this.state = {
            optimizationLevel: defaultOptimizationLevel,
            showCode: false,
            rootElement: this.props.optimizersteps
                ? (new NormalizePlan(this.props.optimizersteps[defaultOptimizationLevel])).getGraph()
                : undefined
        };
    }

    render() {
        if (isMobile) {
            return (
                <div className={m.queryPlan}>
                    <div className={m.graph}>
                        {this.state.rootElement &&
                            <GraphComponent
                                ref={this.graphComponentRef}
                                rootElement={this.state.rootElement}
                                onSvgClick={this.handleSvgClick}
                                onNodeClick={this.handleNodeClick}
                            />}
                    </div>

                    <div className={m.zoomButtons}>
                        <div className={classNames(m.button, m.fetchButton)}
                             onClick={() => this.props.appContext.controller.runPlanVerboseAnalyzeQuery()}>
                            Fetch Analyzed Plan
                        </div>
                        <div
                            className={classNames(m.button, m.zoomInButton, {[m.disabled]: !this.props.optimizersteps})}
                            onClick={this.handleZoomInButton}>
                            +
                        </div>
                        <div
                            className={classNames(m.button, m.zoomOutButton, {[m.disabled]: !this.props.optimizersteps})}
                            onClick={this.handleZoomOutButton}>
                            -
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className={s.queryPlan}>
                <div className={s.optimizerMenu}>
                    <div className={s.stepButtons}>
                        {this.props.optimizersteps && this.props.optimizersteps.map((step: any, index: number) =>
                            <div key={index}
                                 className={classNames(s.button, {[s.active]: index === this.state.optimizationLevel})}
                                 onClick={() => this.handleClickOptimizerStepButton(index)}>
                                {index + 1}: {step.name}
                            </div>)
                        }
                        {this.props.code &&
                            <div className={classNames(s.button, {[s.active]: this.state.showCode})}
                                 onClick={this.handleShowCodeButton}>
                                Generated Code
                            </div>
                        }
                    </div>
                    <div className={classNames(s.button, s.fetchButton)}
                         onClick={() => this.props.appContext.controller.runPlanVerboseAnalyzeQuery()}>
                        Fetch Analyzed Plan
                    </div>
                </div>

                <div className={s.graph}>
                    {this.state.rootElement && !this.state.showCode &&
                        <GraphComponent
                            ref={this.graphComponentRef}
                            rootElement={this.state.rootElement}
                            onSvgClick={this.handleSvgClick}
                            onNodeClick={this.handleNodeClick}
                        />}
                    {this.state.showCode && this.props.code &&
                        <pre><code>
                            {`${renderCode(this.props.code!)}`}
                        </code></pre>
                    }
                </div>

                <div className={s.zoomButtons}>
                    <div className={classNames(s.button, s.zoomButton, {[s.disabled]: !this.props.optimizersteps})}
                         onClick={this.handleZoomInButton}>
                        +
                    </div>
                    <div className={classNames(s.button, s.zoomButton, {[s.disabled]: !this.props.optimizersteps})}
                         onClick={this.handleZoomOutButton}>
                        -
                    </div>
                </div>

                <div className={s.detailComponentContainer}>
                    {this.state.detailsNode &&
                        <DetailComponent
                            detailsNode={this.state.detailsNode}
                            collapsePropertyUpdated={this.graphComponentRef.current ? this.graphComponentRef.current.drawChart : () => null}
                        />}
                </div>

            </div>
        );
    }

    /**
     * Recalculate graph only when the plan or the optimizerStep has been changed
     *
     * @see React.Component
     */
    componentDidUpdate(prevProps: Readonly<IQueryPlanProps>, prevState: Readonly<IQueryPlanState>): void {
        if (this.state.showCode)
            return;
        const optimizationLevel = this.props.optimizersteps !== prevProps.optimizersteps
            ? (this.props.optimizersteps || []).length - 1
            : this.state.optimizationLevel;
        if (optimizationLevel !== prevState.optimizationLevel)
            this.setState(state => ({
                ...state,
                optimizationLevel
            }));
        if (this.props.optimizersteps !== prevProps.optimizersteps || optimizationLevel !== prevState.optimizationLevel)
            this.setState(state => ({
                ...state,
                rootElement: this.props.optimizersteps
                    ? (new NormalizePlan(this.props.optimizersteps[optimizationLevel])).getGraph()
                    : undefined
            }));
    }

    /**
     * Selects a new optimizerStep
     * @param n optimizerStep to select
     */
    private handleClickOptimizerStepButton: (n: number) => void = (n: number) => {
        this.setState(state => ({...state, showCode: false, optimizationLevel: n}));
    };

    /**
     * Shows the generated code
     */
    private handleShowCodeButton: () => void = () => {
        this.setState(state => ({...state, showCode: true, optimizationLevel: -1}));
    };

    /**
     * Unset currently selected detailsNode
     */
    private handleSvgClick: () => void = () => {
        this.setState(state => ({...state, detailsNode: undefined}));
    };

    /**
     * Set currently selected detailsNode
     *
     * @param d3node detailsNode to set
     */
    private handleNodeClick: (d3node: dagreD3.Node) => void = (d3node: dagreD3.Node) => {
        this.setState(state => ({...state, detailsNode: d3node}));
    };

    /**
     * forward zoomIn to GraphComponent
     */
    private handleZoomInButton: () => void = () => {
        this.graphComponentRef.current && this.graphComponentRef.current.zoomIn();
    };

    /**
     * forward zoomOut to GraphComponent
     */
    private handleZoomOutButton: () => void = () => {
        this.graphComponentRef.current && this.graphComponentRef.current.zoomOut();
    };
}

/**
 * Retrieve and update optimizerSteps from redux store, add appContext
 * @see IQueryPlanProps
 */
export default withAppContext(connect((state: Store.RootState) => ({
    optimizersteps: state.labPlanResult?.optimizersteps,
    code: state.labPlanResult?.code
}))(QueryPlan));
