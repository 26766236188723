/* eslint import/no-webpack-loader-syntax: off */
import { QueryList } from '../../store';
import q1a from '!raw-loader!./1a.sql';
import q1b from '!raw-loader!./1b.sql';
import q1c from '!raw-loader!./1c.sql';
import q1d from '!raw-loader!./1d.sql';
import q2a from '!raw-loader!./2a.sql';
import q2b from '!raw-loader!./2b.sql';
import q2c from '!raw-loader!./2c.sql';
import q2d from '!raw-loader!./2d.sql';
import q3a from '!raw-loader!./3a.sql';
import q3b from '!raw-loader!./3b.sql';
import q3c from '!raw-loader!./3c.sql';
import q4a from '!raw-loader!./4a.sql';
import q4b from '!raw-loader!./4b.sql';
import q4c from '!raw-loader!./4c.sql';
import q5a from '!raw-loader!./5a.sql';
import q5b from '!raw-loader!./5b.sql';
import q5c from '!raw-loader!./5c.sql';
import q6a from '!raw-loader!./6a.sql';
import q6b from '!raw-loader!./6b.sql';
import q6c from '!raw-loader!./6c.sql';
import q6d from '!raw-loader!./6d.sql';
import q6e from '!raw-loader!./6e.sql';
import q6f from '!raw-loader!./6f.sql';
import q7a from '!raw-loader!./7a.sql';
import q7b from '!raw-loader!./7b.sql';
import q7c from '!raw-loader!./7c.sql';
import q8a from '!raw-loader!./8a.sql';
import q8b from '!raw-loader!./8b.sql';
import q8c from '!raw-loader!./8c.sql';
import q8d from '!raw-loader!./8d.sql';
import q9a from '!raw-loader!./9a.sql';
import q9b from '!raw-loader!./9b.sql';
import q9c from '!raw-loader!./9c.sql';
import q9d from '!raw-loader!./9d.sql';
import q10a from '!raw-loader!./10a.sql';
import q10b from '!raw-loader!./10b.sql';
import q10c from '!raw-loader!./10c.sql';
import q11a from '!raw-loader!./11a.sql';
import q11b from '!raw-loader!./11b.sql';
import q11c from '!raw-loader!./11c.sql';
import q11d from '!raw-loader!./11d.sql';
import q12a from '!raw-loader!./12a.sql';
import q12b from '!raw-loader!./12b.sql';
import q12c from '!raw-loader!./12c.sql';
import q13a from '!raw-loader!./13a.sql';
import q13b from '!raw-loader!./13b.sql';
import q13c from '!raw-loader!./13c.sql';
import q13d from '!raw-loader!./13d.sql';
import q14a from '!raw-loader!./14a.sql';
import q14b from '!raw-loader!./14b.sql';
import q14c from '!raw-loader!./14c.sql';
import q15a from '!raw-loader!./15a.sql';
import q15b from '!raw-loader!./15b.sql';
import q15c from '!raw-loader!./15c.sql';
import q15d from '!raw-loader!./15d.sql';
import q16a from '!raw-loader!./16a.sql';
import q16b from '!raw-loader!./16b.sql';
import q16c from '!raw-loader!./16c.sql';
import q16d from '!raw-loader!./16d.sql';
import q17a from '!raw-loader!./17a.sql';
import q17b from '!raw-loader!./17b.sql';
import q17c from '!raw-loader!./17c.sql';
import q17d from '!raw-loader!./17d.sql';
import q17e from '!raw-loader!./17e.sql';
import q17f from '!raw-loader!./17f.sql';
import q18a from '!raw-loader!./18a.sql';
import q18b from '!raw-loader!./18b.sql';
import q18c from '!raw-loader!./18c.sql';
import q19a from '!raw-loader!./19a.sql';
import q19b from '!raw-loader!./19b.sql';
import q19c from '!raw-loader!./19c.sql';
import q19d from '!raw-loader!./19d.sql';
import q20a from '!raw-loader!./20a.sql';
import q20b from '!raw-loader!./20b.sql';
import q20c from '!raw-loader!./20c.sql';
import q21a from '!raw-loader!./21a.sql';
import q21b from '!raw-loader!./21b.sql';
import q21c from '!raw-loader!./21c.sql';
import q22a from '!raw-loader!./22a.sql';
import q22b from '!raw-loader!./22b.sql';
import q22c from '!raw-loader!./22c.sql';
import q22d from '!raw-loader!./22d.sql';
import q23a from '!raw-loader!./23a.sql';
import q23b from '!raw-loader!./23b.sql';
import q23c from '!raw-loader!./23c.sql';
import q24a from '!raw-loader!./24a.sql';
import q24b from '!raw-loader!./24b.sql';
import q25a from '!raw-loader!./25a.sql';
import q25b from '!raw-loader!./25b.sql';
import q25c from '!raw-loader!./25c.sql';
import q26a from '!raw-loader!./26a.sql';
import q26b from '!raw-loader!./26b.sql';
import q26c from '!raw-loader!./26c.sql';
import q27a from '!raw-loader!./27a.sql';
import q27b from '!raw-loader!./27b.sql';
import q27c from '!raw-loader!./27c.sql';
import q28a from '!raw-loader!./28a.sql';
import q28b from '!raw-loader!./28b.sql';
import q28c from '!raw-loader!./28c.sql';
import q29a from '!raw-loader!./29a.sql';
import q29b from '!raw-loader!./29b.sql';
import q29c from '!raw-loader!./29c.sql';
import q30a from '!raw-loader!./30a.sql';
import q30b from '!raw-loader!./30b.sql';
import q30c from '!raw-loader!./30c.sql';
import q31a from '!raw-loader!./31a.sql';
import q31b from '!raw-loader!./31b.sql';
import q31c from '!raw-loader!./31c.sql';
import q32a from '!raw-loader!./32a.sql';
import q32b from '!raw-loader!./32b.sql';
import q33a from '!raw-loader!./33a.sql';
import q33b from '!raw-loader!./33b.sql';
import q33c from '!raw-loader!./33c.sql';

export const queries : QueryList = {
    name: "JOB",
    queries: [
        {name: "1a", text: [q1a]},
        {name: "1b", text: [q1b]},
        {name: "1c", text: [q1c]},
        {name: "1d", text: [q1d]},
        {name: "2a", text: [q2a]},
        {name: "2b", text: [q2b]},
        {name: "2c", text: [q2c]},
        {name: "2d", text: [q2d]},
        {name: "3a", text: [q3a]},
        {name: "3b", text: [q3b]},
        {name: "3c", text: [q3c]},
        {name: "4a", text: [q4a]},
        {name: "4b", text: [q4b]},
        {name: "4c", text: [q4c]},
        {name: "5a", text: [q5a]},
        {name: "5b", text: [q5b]},
        {name: "5c", text: [q5c]},
        {name: "6a", text: [q6a]},
        {name: "6b", text: [q6b]},
        {name: "6c", text: [q6c]},
        {name: "6d", text: [q6d]},
        {name: "6e", text: [q6e]},
        {name: "6f", text: [q6f]},
        {name: "7a", text: [q7a]},
        {name: "7b", text: [q7b]},
        {name: "7c", text: [q7c]},
        {name: "8a", text: [q8a]},
        {name: "8b", text: [q8b]},
        {name: "8c", text: [q8c]},
        {name: "8d", text: [q8d]},
        {name: "9a", text: [q9a]},
        {name: "9b", text: [q9b]},
        {name: "9c", text: [q9c]},
        {name: "9d", text: [q9d]},
        {name: "10a", text: [q10a]},
        {name: "10b", text: [q10b]},
        {name: "10c", text: [q10c]},
        {name: "11a", text: [q11a]},
        {name: "11b", text: [q11b]},
        {name: "11c", text: [q11c]},
        {name: "11d", text: [q11d]},
        {name: "12a", text: [q12a]},
        {name: "12b", text: [q12b]},
        {name: "12c", text: [q12c]},
        {name: "13a", text: [q13a]},
        {name: "13b", text: [q13b]},
        {name: "13c", text: [q13c]},
        {name: "13d", text: [q13d]},
        {name: "14a", text: [q14a]},
        {name: "14b", text: [q14b]},
        {name: "14c", text: [q14c]},
        {name: "15a", text: [q15a]},
        {name: "15b", text: [q15b]},
        {name: "15c", text: [q15c]},
        {name: "15d", text: [q15d]},
        {name: "16a", text: [q16a]},
        {name: "16b", text: [q16b]},
        {name: "16c", text: [q16c]},
        {name: "16d", text: [q16d]},
        {name: "17a", text: [q17a]},
        {name: "17b", text: [q17b]},
        {name: "17c", text: [q17c]},
        {name: "17d", text: [q17d]},
        {name: "17e", text: [q17e]},
        {name: "17f", text: [q17f]},
        {name: "18a", text: [q18a]},
        {name: "18b", text: [q18b]},
        {name: "18c", text: [q18c]},
        {name: "19a", text: [q19a]},
        {name: "19b", text: [q19b]},
        {name: "19c", text: [q19c]},
        {name: "19d", text: [q19d]},
        {name: "20a", text: [q20a]},
        {name: "20b", text: [q20b]},
        {name: "20c", text: [q20c]},
        {name: "21a", text: [q21a]},
        {name: "21b", text: [q21b]},
        {name: "21c", text: [q21c]},
        {name: "22a", text: [q22a]},
        {name: "22b", text: [q22b]},
        {name: "22c", text: [q22c]},
        {name: "22d", text: [q22d]},
        {name: "23a", text: [q23a]},
        {name: "23b", text: [q23b]},
        {name: "23c", text: [q23c]},
        {name: "24a", text: [q24a]},
        {name: "24b", text: [q24b]},
        {name: "25a", text: [q25a]},
        {name: "25b", text: [q25b]},
        {name: "25c", text: [q25c]},
        {name: "26a", text: [q26a]},
        {name: "26b", text: [q26b]},
        {name: "26c", text: [q26c]},
        {name: "27a", text: [q27a]},
        {name: "27b", text: [q27b]},
        {name: "27c", text: [q27c]},
        {name: "28a", text: [q28a]},
        {name: "28b", text: [q28b]},
        {name: "28c", text: [q28c]},
        {name: "29a", text: [q29a]},
        {name: "29b", text: [q29b]},
        {name: "29c", text: [q29c]},
        {name: "30a", text: [q30a]},
        {name: "30b", text: [q30b]},
        {name: "30c", text: [q30c]},
        {name: "31a", text: [q31a]},
        {name: "31b", text: [q31b]},
        {name: "31c", text: [q31c]},
        {name: "32a", text: [q32a]},
        {name: "32b", text: [q32b]},
        {name: "33a", text: [q33a]},
        {name: "33b", text: [q33b]},
        {name: "33c", text: [q33c]}
    ]
}
