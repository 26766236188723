import * as Store from '../store';

// Inheriting from error requires a tiny hack as the Error class apparently messes up the prototype chain.
// https://stackoverflow.com/questions/41102060/typescript-extending-error-class
export class ErrorBase extends Error {
    constructor(msg: string) {
        super(String(msg));
        const actualProto = new.target.prototype;
        if (Object.setPrototypeOf) {
            Object.setPrototypeOf(this, actualProto);
        } else {
            /* tslint:disable */
            (this as any)['__proto__'] = actualProto;
            /* tslint:enable */
        }
    }
}

// An error that can be logged in the application log
export class LoggableError extends ErrorBase {
    public logLevel: Store.LogLevel;
    public message: string;
    constructor(message: string, logLevel: Store.LogLevel = Store.LogLevel.LL_WARNING) {
        super(message);
        this.logLevel = logLevel;
        this.message = message;
    }
}

// An error with a http status code
export class UmbraError extends LoggableError {
    public statusCode: number;
    public statusText: string;
    public text: string;
    public details: string;
    constructor(statusCode: number, statusText: string, text: string, details: string, logLevel: Store.LogLevel = Store.LogLevel.LL_WARNING) {
        super(String(text), logLevel);
        this.statusCode = statusCode;
        this.statusText = statusText;
        this.text = text;
        this.details = details;
    }
}
