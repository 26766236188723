import * as React from 'react';
import classNames from 'classnames';
import s from './Spinners.module.scss';

export class FillingBoxSpinner extends React.PureComponent<{ counter: number }> {
    public render() {
        return (
            <div className={s.fillingBoxSpinner}>
                <div
                    className={classNames(
                        [s.outline],
                        (this.props.counter > 0)
                            ? ((this.props.counter & 1) ? [s.state1] : [s.state0])
                            : undefined
                    )}
                >
                    <div className={s.box} />
                </div>
            </div>
        );
    }
}

export class PulsingCircleSpinner extends React.PureComponent<{ counter: number }> {
    public render() {
        return (
            <div className={s.pulsingCircleSpinner}>
                <div
                    className={classNames(
                        [s.outline],
                        (this.props.counter > 0)
                            ? ((this.props.counter & 1) ? [s.state1] : [s.state0])
                            : undefined
                    )}
                >
                    <div className={s.circle} />
                </div>
            </div>
        );
    }
}

interface IRectangleWaveSpinnerProps {
    color?: string;
}

export class RectangleWaveSpinner extends React.PureComponent<IRectangleWaveSpinnerProps> {
    public render() {
        const rectStyle = {
            backgroundColor: this.props.color || "white"
        };
        return (
            <div className={s.rectangleWaveSpinner}>
                <div className={s.rect1} style={rectStyle} />
                <div className={s.rect2} style={rectStyle} />
                <div className={s.rect3} style={rectStyle} />
                <div className={s.rect4} style={rectStyle} />
                <div className={s.rect5} style={rectStyle} />
            </div>
        );
    }
}

