import * as React from 'react';
import SQLLab from './sql-lab/SQLLab';
import ServerSelector from './server-selector/ServerSelector';
import StatusBar from './status-bar/StatusBar';
import NavigationBar from './design-elements/NavigationBar';
import * as Store from '../store';
import { connect } from 'react-redux';

import s from './Router.module.scss';

interface IRouterProps {
    rootView: Store.RootView;
    isHeadless: boolean;
}

class Router extends React.Component<IRouterProps> {
    public render() {
        let rowTemplate = this.props.isHeadless
            ? "0px calc(100% - 24px) 24px"
            : "48px calc(100% - 56px) 24px";
        switch (this.props.rootView) {
            case Store.RootView.SERVER_SELECTOR:
                return (
                    <div className={s.Router}>
                        <ServerSelector />
                    </div>
                );
            case Store.RootView.SQL_LAB:
                return (
                    <div className={s.Router}
                        style={{gridTemplateRows: rowTemplate}}
                    >   
                        {(!this.props.isHeadless) && 
                        <div className={s.NavigationBarContainer}> 
                            <NavigationBar />
                        </div>
                        }
                        <div className={s.SQLLabContainer}>
                            <SQLLab />
                        </div>
                        <div className={s.StatusBarContainer}>
                            <StatusBar />
                        </div>
                    </div>                    
                );
            default:
                return <div />;
        }
    }
}

function mapStateToProps(state: Store.RootState) {
    return {
        rootView: state.rootView,
        isHeadless: (state.appConfig && !!state.appConfig.headless) || false,
    };
}
function mapDispatchToProps(_dispatch: Store.Dispatch) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(Router);

