/* eslint import/no-webpack-loader-syntax: off */
import { QueryList } from '../../store';
import q1 from '!raw-loader!./1.sql';
import q2 from '!raw-loader!./2.sql';
import q3 from '!raw-loader!./3.sql';
import q4 from '!raw-loader!./4.sql';
import q5 from '!raw-loader!./5.sql';
import q6 from '!raw-loader!./6.sql';
import q7 from '!raw-loader!./7.sql';
import q8 from '!raw-loader!./8.sql';
import q9 from '!raw-loader!./9.sql';
import q10 from '!raw-loader!./10.sql';
import q11 from '!raw-loader!./11.sql';
import q12 from '!raw-loader!./12.sql';
import q13 from '!raw-loader!./13.sql';
import q14 from '!raw-loader!./14.sql';
import q15 from '!raw-loader!./15.sql';
import q16 from '!raw-loader!./16.sql';
import q17 from '!raw-loader!./17.sql';
import q18 from '!raw-loader!./18.sql';
import q19 from '!raw-loader!./19.sql';
import q20 from '!raw-loader!./20.sql';
import q21 from '!raw-loader!./21.sql';
import q22 from '!raw-loader!./22.sql';

export const queries : QueryList = {
    name: "TPC-H",
    queries: [
        {name: "1", text: [q1]},
        {name: "2", text: [q2]},
        {name: "3", text: [q3]},
        {name: "4", text: [q4]},
        {name: "5", text: [q5]},
        {name: "6", text: [q6]},
        {name: "7", text: [q7]},
        {name: "8", text: [q8]},
        {name: "9", text: [q9]},
        {name: "10", text: [q10]},
        {name: "11", text: [q11]},
        {name: "12", text: [q12]},
        {name: "13", text: [q13]},
        {name: "14", text: [q14]},
        {name: "15", text: [q15]},
        {name: "16", text: [q16]},
        {name: "17", text: [q17]},
        {name: "18", text: [q18]},
        {name: "19", text: [q19]},
        {name: "20", text: [q20]},
        {name: "21", text: [q21]},
        {name: "22", text: [q22]}
    ]
}
