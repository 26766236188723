import * as Store from './store';
import * as Queries from './queries';

const development: boolean = process.env.NODE_ENV && process.env.NODE_ENV === 'development';

export const getConfig: () => Store.AppConfig = () => {
    return {
        "headless": development ? "http|localhost|8000" : "https|umbra.db.in.tum.de/api",
        "knownServers": [
            {
                "connection": {
                    "protocol": Store.ConnectionProtocol.CP_HTTPS,
                    "host": "umbra.db.in.tum.de/api",
                    "port": null
                },
                "info": "This web interface is not suitable for benchmarking. Your queries run on one of three instances, so cardinality estimates and thus join plans might not be stable. Each instance runs on eight threads on an old server (Xeon E5-2660, 256 GB DDR3 RAM).<br/><br/>The datasets available in the web interface are: TPC-H SF1, TPC-H SF10, TPC-H SF100, TPC-DS SF1, TPC-DS SF10, SSB SF1, SSB SF10, JOB IMDb, and ClickBench.",
                "querySets": [
                    {
                        "id": "tpch",
                        "scales": [1, 10, 100],
                    },
                    {
                        "id": "tpcds",
                        "scales": [1, 10]
                    },
                    {
                        "id": "ssb",
                        "scales": [1, 10]
                    },
                    {
                        "id": "job"
                    },
                    {
                        "id": "ldbc",
                        "scales": [1]
                    },
                    {
                        "id": "clickbench",
                    },
                    {
                        "id": "uni"
                    }
                ]
            },
            {
                "connection": {
                    "protocol": Store.ConnectionProtocol.CP_HTTP,
                    "host": "localhost",
                    "port": 8000
                },
                "info": "Local Umbra instance",
                "querySets": [
                    {
                        "id": "tpch"
                    },
                    {
                        "id": "tpcds"
                    },
                    {
                        "id": "ssb"
                    },
                    {
                        "id": "job"
                    },
                    {
                        "id": "ldbc"
                    },
                    {
                        "id": "clickbench",
                    },
                ]
            }
        ],
        "queryDefinitions": Queries.queries,
    }
};
