import * as Immutable from 'immutable';
import * as React from 'react';
import * as Store from '../../store';
import { connect } from 'react-redux';
import { ArrowForwardIosIcon } from '../../svg/Icons';
import { PulsingCircleSpinner } from '../design-elements/Spinners';
import { Logo } from '../../svg/Logo';
import { isElectron } from '../../utils/UserAgent';

import s from './ServerSelector.module.scss';

interface IServerSelectorProps {
    serverConfigs: Immutable.Seq.Indexed<[string, Store.ServerConfig]>;
    serverInfos: Immutable.Seq.Keyed<string, Store.ServerInfo>;
    selectServer: (key: string) => void;
}

class ServerSelector extends React.Component<IServerSelectorProps> {
    public renderServerEntry(key: string, config: Store.ServerConfig) {
        const serverInfo = this.props.serverInfos.get(key) || new Store.ServerInfo();
        let serverDescription;
        if ("path" in config.connection) {
            serverDescription = (
                <div className={s.hostname}>
                    {config.connection.path}
                </div>
            );
        } else {
            serverDescription = (
                <>
                   <div className={s.hostname}>
                        {config.connection.host}
                    </div>
                    <div className={s.protocol}>
                        Proto: {config.connection.protocol}
                    </div>
                    <div className={s.port}>
                        Port: {config.connection.port}
                    </div>
                </>
            );
        }
        return (
            <div key={key} className={s.entry}>
                <div className={s.status}>
                    <PulsingCircleSpinner
                        key={serverInfo.connectionHeartbeat}
                        counter={serverInfo.connectionHeartbeat}
                    />
                </div>
                {serverDescription}
                <div className={s.border} />
                <div
                    className={s.arrow}
                    onClick={() => this.props.selectServer(key)}
                >
                    <ArrowForwardIosIcon width="18px" height="18px" fill="rgb(42, 63, 95)" />
                </div>
            </div>
        );
    }

    public render() {
        return (
            <div className={s.serverSelector}>
                {
                    !isElectron() && 
                    <div>
                        <div className={s.hireBackground} />
                        <div className={s.hireText}>
                            @TUM ?
                        </div>
                    </div>
                }
                <div className={s.header}>
                    <div className={s.logo}>
                        <Logo width="100%" height="100%" />
                    </div>
                    <div className={s.name}>
                        Umbra Analytics
                    </div>
                </div>
                <div className={s.listContainer}>
                    <div className={s.header}>
                        Servers
                    </div>
                    <div className={s.content}>
                        {
                            this.props.serverConfigs.map((entry) => {
                                // TODO why does immutable.js return undefined when mapping a function?
                                if (!entry) { return <div />; }
                                return this.renderServerEntry(entry[0], entry[1]);
                            })
                        }
                    </div>
                </div>
                <div className={s.version}>
                    version: {process.env.REACT_APP_VERSION}
                </div>
            </div>
        );
    }

}

function mapStateToProps(state: Store.RootState) {
    return {
        serverConfigs: state.serverConfigs.entrySeq() as Immutable.Seq.Indexed<[string, Store.ServerConfig]>,
        serverInfos: state.serverInfos.toKeyedSeq(),
    };
}
function mapDispatchToProps(dispatch: Store.Dispatch) {
    return {
        selectServer: (key: string) => dispatch(Store.selectServer(key))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerSelector);

