import * as Immutable from 'immutable';

// ---------------------------------------------------------------------------
// Enums
// ---------------------------------------------------------------------------

// A view
export enum RootView {
    SERVER_SELECTOR = 0,
    SQL_LAB = 1,
    DASHBOARD_EDITOR = 2
}

// A connection protocol
export enum ConnectionProtocol {
    CP_HTTP = "http",
    CP_HTTPS = "https"
}

// A connection status
export enum ConnectionStatus {
    CS_UNDEFINED = 0,
    CS_CONNECTED = 1,
    CS_DISCONNECTED = 2,
}

// A log level
export enum LogLevel {
    LL_UNDEFINED = 0,
    LL_DEBUG = 1,
    LL_INFO = 2,
    LL_WARNING = 3,
    LL_ERROR = 4,
}

// A data viz type
export enum DataVizType {
    DVT_TABLE = 0,
    DVT_CHART = 1,
}

// ---------------------------------------------------------------------------
// State Model
// ---------------------------------------------------------------------------

// An application config
export type AppConfig = {
    headless?: string;
    knownServers?: ServerConfig[]
    queryDefinitions?: QueryDefinitions;
}

// A data source
export type Query = {
    name: string;
    text: string[];
}

// A list of queries
export type QueryList = {
    name: string;
    queries: Query[];
}

// A query set definition for a benchmark, e.g., TPC-H
export class QueryDefinitions {
    [id: string]: QueryList;
}

// A set of available query scales and skews of a query definition
export class QuerySet {
    public id: string = "";
    public scales?: number[] = [];
    public skews?: number[][] = [];
}

// The server connection info for an external server
export class ExternalConnectionInfo {
    public protocol: ConnectionProtocol = ConnectionProtocol.CP_HTTP;
    public host: string = "";
    public port: number | null = 8000;
}

// The server connection info for an internal server, i.e. a connection that is
// accessible on the same webserver as analytics on a sub-path
export class InternalConnectionInfo {
    public path: string = "";
}

// The server configuration
export class ServerConfig {
    public static buildKey(config: ServerConfig) {
        if ("path" in config.connection) {
            return `${config.connection.path}`;
        } else if (config.connection.port !== null) {
            return `${config.connection.protocol}|${config.connection.host}|${config.connection.port}`;
        } else {
            return `${config.connection.protocol}|${config.connection.host}`;
        }
    }

    public connection: ExternalConnectionInfo | InternalConnectionInfo = new ExternalConnectionInfo();
    public info: string = ""
    public querySets: QuerySet[] = [];
}

// The server status
export class ServerInfo {
    public version: string = "";
    public lastUpdate: number = 0;
    public connectionAttempts: number = 0;
    public connectionStatus: ConnectionStatus = ConnectionStatus.CS_UNDEFINED;
    public connectionHeartbeat: number = -1;
}

// A data source result column
export class QueryResultColumn {
    public columnName: string = "";
    public columnType: string = "";
    public data: any[] = [];
}

// A data source result
export class QueryResult {
    public columns: QueryResultColumn[] = [];
    public compilationTime: number = 0;
    public executionTime: number = 0;
    public resultCount: number = 0;
}

// A query plan result
export class PlanResult {
    public optimizersteps: {
        name: string,
        plan: {
            plan: any,
            output: { name: string, iu: string, collate: string }[],
            type: number,
            query: boolean,
            analyzePlanPipelines?: { start: number, stop: number, duration: number, operators: number[] }[]
        }
    }[] = [];
    public code: any = undefined
}

// The log entry
export class LogEntry {
    public timestamp: Date = new Date();
    public level: LogLevel = LogLevel.LL_UNDEFINED;
    public text: string = "";
}

// The query info
export class QueryInfo {
    public schemaId: string = "";
    public scale: number | undefined;
    public skew: number | undefined;
}

// ---------------------------------------------------------------------------
// Root state type
// ---------------------------------------------------------------------------

// A root state
export class RootState {
    // The app config
    public appConfig: AppConfig | null;
    // The app config load is pending
    public appConfigLoadPending: boolean;

    // The server configs
    public serverConfigs: Immutable.Map<string, ServerConfig>;
    // The server status
    public serverInfos: Immutable.Map<string, ServerInfo>;

    // The log entries
    public logs: Immutable.List<LogEntry>;
    // The warnings
    public logWarnings: number;
    // The log viewer open
    public logViewerOpen: boolean;

    // The root view
    public rootView: RootView;
    // The selected server
    public selectedServer: string | null;

    // The selected view of the sql lab
    public labView: number;
    // The start of the last query
    public labQueryStart: number | null;
    // The duration of the last query
    public labQueryDuration: number | null;
    // The results of the last query
    public labQueryResult: QueryResult | null;
    // The query failed
    public labQueryError: Error | null;
    // The query text
    public labQueryText: string;
    // The theme
    public labTheme: string;
    // The schema id for the query
    public queryInfo: QueryInfo;

    // The plans of the last query
    public labPlanResult: PlanResult | null;

    // Constructor
    constructor() {
        this.appConfig = null;
        this.appConfigLoadPending = true;
        this.serverConfigs = Immutable.Map<string, ServerConfig>();
        this.serverInfos = Immutable.Map<string, ServerInfo>();
        this.logs = Immutable.List<LogEntry>();
        this.logWarnings = 0;
        this.logViewerOpen = false;
        this.rootView = RootView.SERVER_SELECTOR;
        this.selectedServer = null;
        this.labView = 0;
        this.labQueryStart = null;
        this.labQueryDuration = null;
        this.labQueryResult = null;
        this.labQueryError = null;
        this.labQueryText = "select count(*) from lineitem;";
        this.labTheme = "dark";
        this.labPlanResult = null;
        this.queryInfo = {
            schemaId: "tpch",
            scale: 1,
            skew: 0
        }
        return;
    }
}

