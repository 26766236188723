import { UmbraError, LoggableError } from '../utils/Error';

const defaultTimeout = 7000;

class UmbraExceptionMessage {
    status: string = "";
    exception: string = "";
    details: string = "";
}

// Fetch something with timeout
export async function load<T>(url: string, options: RequestInit): Promise<T> {
    let resp = await fetch(url, options);
    if (!resp.ok) {
        let data = await resp.text();
        let dataJSON = JSON.parse(data);
        if (dataJSON && dataJSON.hasOwnProperty("exception")) {
            let e = dataJSON as UmbraExceptionMessage;
            throw new UmbraError(resp.status, resp.statusText, e.exception, e.details || "");
        } else {
            throw new UmbraError(resp.status, resp.statusText, data, "");
        }
    }
    let data = await resp.json() as T;
    return data;
}

// Fetch something with timeout
export async function loadWithTimeout<T>(url: string, options: RequestInit, timeout: number = defaultTimeout): Promise<T> {
    const timeoutPromise = new Promise<T>((_, reject) => {
        return setTimeout(() => reject(new LoggableError('Request timed out')), timeout)
    });
    return Promise.race([
        load<T>(url, options),
        timeoutPromise
    ]);
}

// Fetch something from public folder
export function loadFromPublic<T>(path: string, timeout: number = defaultTimeout): Promise<T> {
    const url = process.env.PUBLIC_URL + '/' + path;
    return loadWithTimeout<T>(url, {}, timeout);
}
