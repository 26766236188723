/* eslint import/no-webpack-loader-syntax: off */
import { QueryList } from '../../store';
import q1 from '!raw-loader!./1.sql';
import q2 from '!raw-loader!./2.sql';
import q3 from '!raw-loader!./3.sql';
import q4 from '!raw-loader!./4.sql';
import q5 from '!raw-loader!./5.sql';
import q6 from '!raw-loader!./6.sql';
import q7 from '!raw-loader!./7.sql';
import q8 from '!raw-loader!./8.sql';
import q9 from '!raw-loader!./9.sql';
import q10 from '!raw-loader!./10.sql';
import q11 from '!raw-loader!./11.sql';
import q12 from '!raw-loader!./12.sql';
import q13 from '!raw-loader!./13.sql';
import q14a from '!raw-loader!./14a.sql';
import q14b from '!raw-loader!./14b.sql';
import q15 from '!raw-loader!./15.sql';
import q16 from '!raw-loader!./16.sql';
import q17 from '!raw-loader!./17.sql';
import q18 from '!raw-loader!./18.sql';
import q19 from '!raw-loader!./19.sql';
import q20 from '!raw-loader!./20.sql';
import q21 from '!raw-loader!./21.sql';
import q22 from '!raw-loader!./22.sql';
import q23a from '!raw-loader!./23a.sql';
import q23b from '!raw-loader!./23b.sql';
import q24a from '!raw-loader!./24a.sql';
import q24b from '!raw-loader!./24b.sql';
import q25 from '!raw-loader!./25.sql';
import q26 from '!raw-loader!./26.sql';
import q27 from '!raw-loader!./27.sql';
import q28 from '!raw-loader!./28.sql';
import q29 from '!raw-loader!./29.sql';
import q30 from '!raw-loader!./30.sql';
import q31 from '!raw-loader!./31.sql';
import q32 from '!raw-loader!./32.sql';
import q33 from '!raw-loader!./33.sql';
import q34 from '!raw-loader!./34.sql';
import q35 from '!raw-loader!./35.sql';
import q36 from '!raw-loader!./36.sql';
import q37 from '!raw-loader!./37.sql';
import q38 from '!raw-loader!./38.sql';
import q39a from '!raw-loader!./39a.sql';
import q39b from '!raw-loader!./39b.sql';
import q40 from '!raw-loader!./40.sql';
import q41 from '!raw-loader!./41.sql';
import q42 from '!raw-loader!./42.sql';
import q43 from '!raw-loader!./43.sql';
import q44 from '!raw-loader!./44.sql';
import q45 from '!raw-loader!./45.sql';
import q46 from '!raw-loader!./46.sql';
import q47 from '!raw-loader!./47.sql';
import q48 from '!raw-loader!./48.sql';
import q49 from '!raw-loader!./49.sql';
import q50 from '!raw-loader!./50.sql';
import q51 from '!raw-loader!./51.sql';
import q52 from '!raw-loader!./52.sql';
import q53 from '!raw-loader!./53.sql';
import q54 from '!raw-loader!./54.sql';
import q55 from '!raw-loader!./55.sql';
import q56 from '!raw-loader!./56.sql';
import q57 from '!raw-loader!./57.sql';
import q58 from '!raw-loader!./58.sql';
import q59 from '!raw-loader!./59.sql';
import q60 from '!raw-loader!./60.sql';
import q61 from '!raw-loader!./61.sql';
import q62 from '!raw-loader!./62.sql';
import q63 from '!raw-loader!./63.sql';
import q64 from '!raw-loader!./64.sql';
import q65 from '!raw-loader!./65.sql';
import q66 from '!raw-loader!./66.sql';
import q67 from '!raw-loader!./67.sql';
import q68 from '!raw-loader!./68.sql';
import q69 from '!raw-loader!./69.sql';
import q70 from '!raw-loader!./70.sql';
import q71 from '!raw-loader!./71.sql';
import q72 from '!raw-loader!./72.sql';
import q73 from '!raw-loader!./73.sql';
import q74 from '!raw-loader!./74.sql';
import q75 from '!raw-loader!./75.sql';
import q76 from '!raw-loader!./76.sql';
import q77 from '!raw-loader!./77.sql';
import q78 from '!raw-loader!./78.sql';
import q79 from '!raw-loader!./79.sql';
import q80 from '!raw-loader!./80.sql';
import q81 from '!raw-loader!./81.sql';
import q82 from '!raw-loader!./82.sql';
import q83 from '!raw-loader!./83.sql';
import q84 from '!raw-loader!./84.sql';
import q85 from '!raw-loader!./85.sql';
import q86 from '!raw-loader!./86.sql';
import q87 from '!raw-loader!./87.sql';
import q88 from '!raw-loader!./88.sql';
import q89 from '!raw-loader!./89.sql';
import q90 from '!raw-loader!./90.sql';
import q91 from '!raw-loader!./91.sql';
import q92 from '!raw-loader!./92.sql';
import q93 from '!raw-loader!./93.sql';
import q94 from '!raw-loader!./94.sql';
import q95 from '!raw-loader!./95.sql';
import q96 from '!raw-loader!./96.sql';
import q97 from '!raw-loader!./97.sql';
import q98 from '!raw-loader!./98.sql';
import q99 from '!raw-loader!./99.sql';

export const queries : QueryList = {
    name: "TPC-DS",
    queries: [
        {name: "1", text: [q1]},
        {name: "2", text: [q2]},
        {name: "3", text: [q3]},
        {name: "4", text: [q4]},
        {name: "5", text: [q5]},
        {name: "6", text: [q6]},
        {name: "7", text: [q7]},
        {name: "8", text: [q8]},
        {name: "9", text: [q9]},
        {name: "10", text: [q10]},
        {name: "11", text: [q11]},
        {name: "12", text: [q12]},
        {name: "13", text: [q13]},
        {name: "14a", text: [q14a]},
        {name: "14b", text: [q14b]},
        {name: "15", text: [q15]},
        {name: "16", text: [q16]},
        {name: "17", text: [q17]},
        {name: "18", text: [q18]},
        {name: "19", text: [q19]},
        {name: "20", text: [q20]},
        {name: "21", text: [q21]},
        {name: "22", text: [q22]},
        {name: "23a", text: [q23a]},
        {name: "23b", text: [q23b]},
        {name: "24a", text: [q24a]},
        {name: "24b", text: [q24b]},
        {name: "25", text: [q25]},
        {name: "26", text: [q26]},
        {name: "27", text: [q27]},
        {name: "28", text: [q28]},
        {name: "29", text: [q29]},
        {name: "30", text: [q30]},
        {name: "31", text: [q31]},
        {name: "32", text: [q32]},
        {name: "33", text: [q33]},
        {name: "34", text: [q34]},
        {name: "35", text: [q35]},
        {name: "36", text: [q36]},
        {name: "37", text: [q37]},
        {name: "38", text: [q38]},
        {name: "39a", text: [q39a]},
        {name: "39b", text: [q39b]},
        {name: "40", text: [q40]},
        {name: "41", text: [q41]},
        {name: "42", text: [q42]},
        {name: "43", text: [q43]},
        {name: "44", text: [q44]},
        {name: "45", text: [q45]},
        {name: "46", text: [q46]},
        {name: "47", text: [q47]},
        {name: "48", text: [q48]},
        {name: "49", text: [q49]},
        {name: "50", text: [q50]},
        {name: "51", text: [q51]},
        {name: "52", text: [q52]},
        {name: "53", text: [q53]},
        {name: "54", text: [q54]},
        {name: "55", text: [q55]},
        {name: "56", text: [q56]},
        {name: "57", text: [q57]},
        {name: "58", text: [q58]},
        {name: "59", text: [q59]},
        {name: "60", text: [q60]},
        {name: "61", text: [q61]},
        {name: "62", text: [q62]},
        {name: "63", text: [q63]},
        {name: "64", text: [q64]},
        {name: "65", text: [q65]},
        {name: "66", text: [q66]},
        {name: "67", text: [q67]},
        {name: "68", text: [q68]},
        {name: "69", text: [q69]},
        {name: "70", text: [q70]},
        {name: "71", text: [q71]},
        {name: "72", text: [q72]},
        {name: "73", text: [q73]},
        {name: "74", text: [q74]},
        {name: "75", text: [q75]},
        {name: "76", text: [q76]},
        {name: "77", text: [q77]},
        {name: "78", text: [q78]},
        {name: "79", text: [q79]},
        {name: "80", text: [q80]},
        {name: "81", text: [q81]},
        {name: "82", text: [q82]},
        {name: "83", text: [q83]},
        {name: "84", text: [q84]},
        {name: "85", text: [q85]},
        {name: "86", text: [q86]},
        {name: "87", text: [q87]},
        {name: "88", text: [q88]},
        {name: "89", text: [q89]},
        {name: "90", text: [q90]},
        {name: "91", text: [q91]},
        {name: "92", text: [q92]},
        {name: "93", text: [q93]},
        {name: "94", text: [q94]},
        {name: "95", text: [q95]},
        {name: "96", text: [q96]},
        {name: "97", text: [q97]},
        {name: "98", text: [q98]},
        {name: "99", text: [q99]}
    ]
}
