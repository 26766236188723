import * as React from 'react';
import * as Store from '../../store';
import { connect } from 'react-redux';
import { Logo } from '../../svg/Logo';

import s from './NavigationBar.module.scss';

interface INavigationBarProps {
    deselectServer: () => void;
    navigateRoot: (view: Store.RootView) => void;
}

class NavigationBar extends React.Component<INavigationBarProps> {
    public render() {
        return (
            <div className={s.navigationBar}>
                <div className={s.logo}>
                    <Logo width="100%" height="100%" />
                </div>
                <div className={s.name}>
                    Umbra Analytics
                </div>
            </div>
        );
    }
}

function mapStateToProps(_state: Store.RootState) {
    return {};
}
function mapDispatchToProps(dispatch: Store.Dispatch) {
    return {
        deselectServer: () => { dispatch(Store.deselectServer()); },
        navigateRoot: (view: Store.RootView) => { dispatch(Store.navigateRoot(view)); },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationBar);

