import Loadable from 'react-loadable';
import * as React from 'react';
import { RectangleWaveSpinner } from '../../design-elements/Spinners';

import s from './SQLEditorLoader.module.scss';

function loadingSpinner() {
    return (
        <div className={s.SQLEditorLoader}>
            <RectangleWaveSpinner />
        </div>
    );
}

const SQLEditorLoadable = Loadable({
    loader: () => import('./SQLEditor'),
    loading: loadingSpinner
});

export default class SQLEditorLoader extends React.Component {
    public render() {
        return <SQLEditorLoadable />;
    }
}
